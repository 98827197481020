var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "tasktable",
          attrs: {
            title: "관련 순회점검 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            columnSetting: false,
            isFullScreen: false,
            usePaging: false,
            expandAll: true,
            selection: "multiple",
            rowKey: "saiPatrolId",
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.deleteTask },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addTask },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }